<template>
  <div class="p-container">
    <div class="page-outter">
      <div align="center">
        <img
          class="p-title"
          v-lazy="require('../../../assets/images/title1.png')"
        >
        <div class="p-d1">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div
                class="p-item"
                align="center"
              >
                <img
                  class="m1"
                  v-lazy="item.icon"
                />
                <span class="s1">{{item.name}}</span>
                <span
                  class="s2"
                  v-html="item.remark"
                >
                </span>
              </div>
            </li>
          </ul>
          <div style="clear:both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "platform",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/b-1.png"),
          name: "产业全链",
          remark: `
                  为行业服务商</br>
                  提供数字化技术服务</br>
                  打造数字生态闭环</br>
                  实现数据的全程跟踪与沉淀</br>
                  挖掘大数据价值，助推产业升级
          `,
        },
        {
          icon: require("../../../assets/images/b-2.png"),
          name: "精细运营",
          remark: `
                  基于不同产品服务场景，针对性</br>
                  开展公域+私域的流量运营</br>
                  社交+KOL的粉丝运营</br>
                  品牌+内容的整合运营，用户+</br>
                  活动的定向运营，以精细化运营</br>
                  实现平台及客户价值的有效增长
          `,
        },
        {
          icon: require("../../../assets/images/b-3.png"),
          name: "打造增量",
          remark: `
                  纵深挖掘用户的健康需求，通过</br>
                  转化服务价值、重构业务生态</br>
                  打造行业的</br>
                  增量市场，实现行业利益共赢   
          `,
        },
        {
          icon: require("../../../assets/images/b-4.png"),
          name: "持续盈利",
          remark: `
                  通过对市场需求的深度挖掘</br>
                  定制化服务场景的构建，利用</br>
                  互联网+大数据技术，将流量及</br>
                  用户需求有效转化为商业价值</br>
                  实现平台与客户的良性成长
          `,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.p-container {
  width: 100%;
  border-top: 1px solid #fff;
  background: #fff;
  padding-bottom: 2.4rem;
}
.p-title {
  width: 7rem;
  margin-top: 2.4rem;
}
.p-d1 {
  display: flex;
  justify-content: center;
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    li {
      margin-top: 50px;
      float: left;
      margin-left: 0.35rem;
      .p-item {
        width: 210px;
        height: 300px;
        background: #f3f3f3;
        .m1 {
          width: 54px;
          margin-top: 1rem;
        }
        .s1 {
          display: block;
          font-size: 14px;
          color: #6aa5ff;
          margin-top: 0.1rem;
        }
        .s2 {
          display: block;
          font-size: 12px;
          color: #aaaaaa;
          line-height: 2;
          margin-top: 14px;
        }
      }
    }
  }
}
</style>